<template>
  <div class="w-full">
    <div
      v-if="days.length > 1"
      class="flex justify-center mb-8 max-w-full overflow-x-auto"
    >
      <button
        v-for="(day, dayIndex) in props.days"
        :key="`D${dayIndex}`"
        type="button"
        class="transition py-2 px-12 text-center border"
        :class="{
          'rounded-l': dayIndex === 0,
          'rounded-r': dayIndex === props.days.length - 1,
          'border-(bg-emphasis) bg-(bg-emphasis-muted)': dayIndex === dayIndexSelected,
        }"
        @click="dayIndexSelected = dayIndex"
      >
        <h3
          class="text-lg"
          :class="{
            'text-(fg-accent)': dayIndex === dayIndexSelected,
          }"
        >
          {{ _upperFirst(getFormattedDate(day.date, {
            timezone: props.event?.general?.timezone,
            lang: props.section.lang.value.replace('_', ''),
            format: 'dddd',
          })) }}<br>
          <span class="font-normal text-base">
            {{ _upperFirst(getFormattedDate(day.date, {
              timezone: props.event?.general?.timezone,
              lang: props.section.lang.value.replace('_', ''),
              format: 'll',
            })) }}
          </span>
        </h3>
      </button>
    </div>
    <transition
      name="custom-classes"
      :enter-from-class="'translate-x-4 opacity-0'"
      :leave-active-class="'-translate-x-4 opacity-0'"
      :duration="300"
      mode="out-in"
    >
      <div
        :key="dayIndexSelected"
        class="transition-all"
      >
        <div
          v-for="(dayItem, dayIndex) in props.days"
          :key="`D${dayIndex}`"
        >
          <div
            v-show="dayIndex === dayIndexSelected"
          >
            <div
              v-if="daySelected?.stages?.length"
              :key="dayIndexSelected"
              class="flex gap-6 lg:gap-12"
              :class="{
                'overflow-x-auto ': daySelected?.stages?.length > 1,
                'justify-center': daySelected?.stages?.length === 1,
              }"
            >
              <div
                v-for="(stage, stageIndex) in daySelected.stages"
                :key="`S${stageIndex}`"
                class="flex-1 max-w-xl min-w-[18.75rem]"
              >
                <div
                  :class="[
                    props.section.styles.boxed.value,
                  ]"
                >
                  <div
                    v-if="daySelected.stages?.length > 1 && stage.program?.length"
                    class="px-4 py-2 font-bold rounded bg-(bg-subtle) mb-6"
                    :class="[
                      props.section.styles.textAlignment.value,
                    ]"
                    v-html="sanitize(stage?.[props.section.lang.value]?.name)"
                  />
                  <div
                    v-for="(programItem, programIndex) in stage.program"
                    :key="`P${programIndex}`"
                    class="w-full mb-6"
                  >
                    <div
                      :class="[
                        props.section.styles.textAlignment.value,
                        'p-2',
                        { 'group/programItem cursor-pointer transition hover:bg-(bg-subtle)': programItem?.[props.section.lang.value]?.description?.length > useBuilderStore().agendaMaxDescriptionLength },
                      ]"
                      @click="programItem?.[props.section.lang.value]?.description?.length > useBuilderStore().agendaMaxDescriptionLength ? isDialogOpen[createDialogKey({ dayIndex, stageIndex, programIndex })] = true : ''"
                    >
                      <div class="text-(fg-subtle)">
                        <template v-if="programItem.startTime !== programItem.endTime">
                          {{ programItem.startTime }} - {{ programItem.endTime }}
                        </template>
                        <template v-else>
                          {{ programItem.startTime }}
                        </template>
                      </div>
                      <strong
                        :class="{ 'transition group-hover/programItem:text-(fg-accent)': programItem?.[props.section.lang.value]?.description?.length > useBuilderStore().agendaMaxDescriptionLength }"
                        v-html="sanitize(programItem?.[props.section.lang.value]?.title)"
                      />
                      <div
                        class="text-(fg-subtle)"
                        v-html="sanitize(_truncate(programItem?.[props.section.lang.value]?.description, { length: useBuilderStore().agendaMaxDescriptionLength }))"
                      />
                      <div
                        v-if="programItem.coverImage"
                        class="flex"
                        :class="[
                          props.section.styles.textAlignment.value,
                        ]"
                      >
                        <div
                          class="overflow-hidden mt-2"
                          :class="[
                            props.section.styles.imageSize.value,
                            props.section.styles.imageCrop.value,
                            props.section.styles.cornerRadius.value,
                          ]"
                        >
                          <img
                            :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-1140,c-at_max/' : ''}${programItem.coverImage}`"
                            class="h-full object-cover"
                            :alt="sanitize(programItem?.[props.section.lang.value]?.title)"
                          >
                        </div>
                      </div>
                    </div>
                    <PageAgendaBasicItemDialog
                      v-if="isDialogOpen[createDialogKey({ dayIndex, stageIndex, programIndex })]"
                      :program-item="programItem"
                      :lang="props.section.lang.value"
                      :event="props.event"
                      @close="isDialogOpen[createDialogKey({ dayIndex, stageIndex, programIndex })] = false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
const props = defineProps({
  days: {
    type: Array,
    default: () => ([]),
  },

  section: {
    type: Object,
    default: () => ({}),
  },

  event: {
    type: Object,
    default: () => ({}),
  },
});

// Refs
const dayIndexSelected = ref(0);
const isDialogOpen = ref([]);

// Computed/Days
const daySelected = computed(() => {
  return props.days[dayIndexSelected.value];
});

// Methods
const createDialogKey = ({ dayIndex, stageIndex, programIndex }) => {
  return `${dayIndex}${stageIndex}${programIndex}`;
};
</script>
