<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div
      class="container"
    >
      <div
        class="flex"
        :class="[
          section.data?.value.daysMode === 'columns' ? (days.length === 1 ? `max-w-xl mx-auto ${section.styles.textAlignment.value}` : section.styles.textAlignment.value) : '',
          section.data?.value.daysMode === 'tabs' ? (days.length === 1 ? `max-w-xl mx-auto ${section.styles.textAlignment.value}` : 'justify-center') : '',
        ]"
      >
        <EventTimezoneDiffBadge
          :date="props.event?.general?.startDate"
          :timezone="props.event?.general?.timezone"
          :lang="section.lang?.value?.replace('_', '')"
          :class="section.data?.value.daysMode === 'rows' ? (days.length === 1 ? 'mb-2' : 'mb-4') : 'mb-6'"
        />
      </div>

      <PageAgendaBasicRows
        v-if="section.data?.value.daysMode === 'rows'"
        :days="days"
        :section="section"
        :event="props.event"
      />
      <PageAgendaBasicColumns
        v-if="section.data?.value.daysMode === 'columns'"
        :days="days"
        :section="section"
        :event="props.event"
      />
      <PageAgendaBasicTabs
        v-if="section.data?.value.daysMode === 'tabs'"
        :days="days"
        :section="section"
        :event="props.event"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  event: {
    type: Object,
    default: () => ({
      general: {
        startDate: useDayjs().format('YYYY-MM-DDTHH:mmZZ'),
        endDate: useDayjs().add(3, 'days').format('YYYY-MM-DDTHH:mmZZ'),
      },
    }),
  },
});
const section = usePageSection(props);

// Refs
const days = ref([]);

// Methods
const initAgenda = () => {
  days.value = [];
  const daysDiffAmount = useDayjs(props.event?.general?.endDate).tz(props.event?.general?.timezone).startOf('day').diff(useDayjs(props.event?.general?.startDate).tz(props.event?.general?.timezone).startOf('day'), 'days');
  const items = section.data.value.items;

  for (let dayIndex = 0; dayIndex <= daysDiffAmount; dayIndex++) {
    const stages = [];
    const program = items.filter((item) => item.dayIndex === dayIndex).map((item) => ({
      ...item,
      startTime: getFormattedDate(
        getAgendaItemDate({
          date: useDayjs(props.event?.general?.startDate).tz(props.event?.general?.timezone).add(dayIndex, 'days').utc().format(),
          time: item.startTime,
          timezone: props.event?.general?.timezone,
        }),
        {
          lang: section.lang.value?.replace('_', ''),
          format: 'LT',
          timezone: props.event?.general?.timezone,
        }),
      endTime: getFormattedDate(
        getAgendaItemDate({
          date: useDayjs(props.event?.general?.startDate).tz(props.event?.general?.timezone).add(dayIndex, 'days').utc().format(),
          time: item.endTime,
          timezone: props.event?.general?.timezone,
        }),
        {
          lang: section.lang.value?.replace('_', ''),
          format: 'LT',
          timezone: props.event?.general?.timezone,
        }),
    }));

    if (section.data.value.stages?.length > 1) {
      section.data.value.stages?.forEach((stage, index) => {
        let stageProgram = program.filter((programItem) => programItem.stage === stage.id);

        if (index === 0) {
          stageProgram = [
            ...stageProgram,
            ...program.filter((programItem) => !programItem.stage),
          ];
        }

        if (stageProgram?.length) {
          stages.push({
            ...stage,
            program: stageProgram,
          });
        }
      });
    } else if (program?.length) {
      stages.push({
        program,
      });
    }

    if (stages?.length) {
      days.value.push({
        date: useDayjs(props.event?.general?.startDate).tz(props.event?.general?.timezone).add(dayIndex, 'days').utc().format(),
        stages,
      });
    }
  }
};

// Watchers
watch(() => section.data.value, initAgenda, { deep: true });
watch(() => section.lang.value, initAgenda);

initAgenda();
</script>
