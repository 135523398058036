<template>
  <div
    class="flex flex-col gap-6 lg:gap-12"
    :class="{
      'md:grid md:grid-cols-2': props.days?.length > 1,
      'max-w-xl mr-auto ml-auto': props.days?.length === 1,
    }"
  >
    <div
      v-for="(day, dayIndex) in props.days"
      :key="`D${dayIndex}`"
    >
      <div
        :class="[
          props.section.styles.boxed.value,
        ]"
      >
        <h3
          v-if="days.length > 1"
          class="mb-6"
          :class="[
            props.section.styles.textAlignment.value,
          ]"
        >
          {{ _upperFirst(getFormattedDate(day.date, {
            timezone: props.event?.general?.timezone,
            lang: props.section.lang.value.replace('_', ''),
            format: 'custom-fullMonthNoYear',
          })) }}
        </h3>
        <div
          v-for="(stage, stageIndex) in day.stages"
          :key="`S${stageIndex}`"
        >
          <div
            v-if="day.stages?.length > 1 && stage.program?.length"
            class="px-4 py-2 font-bold rounded bg-(bg-subtle) mb-6"
            :class="[
              props.section.styles.textAlignment.value,
            ]"
            v-html="sanitize(stage?.[props.section.lang.value]?.name)"
          />
          <div
            v-for="(programItem, programIndex) in stage.program"
            :key="`P${programIndex}`"
            class="w-full mb-6 p-2"
            :class="{
              'group/programItem cursor-pointer transition hover:bg-(bg-subtle)': programItem?.[props.section.lang.value]?.description?.length > useBuilderStore().agendaMaxDescriptionLength,
            }"
          >
            <div
              :class="[
                props.section.styles.textAlignment.value,
              ]"
              @click="programItem?.[props.section.lang.value]?.description?.length > useBuilderStore().agendaMaxDescriptionLength ? isDialogOpen[createDialogKey({ dayIndex, stageIndex, programIndex })] = true : ''"
            >
              <div class="text-(fg-subtle)">
                <template v-if="programItem.startTime !== programItem.endTime">
                  {{ programItem.startTime }} - {{ programItem.endTime }}
                </template>
                <template v-else>
                  {{ programItem.startTime }}
                </template>
              </div>
              <strong
                :class="{ 'transition group-hover/programItem:text-(fg-accent)': programItem?.[props.section.lang.value]?.description?.length > useBuilderStore().agendaMaxDescriptionLength }"
                v-html="sanitize(programItem?.[props.section.lang.value]?.title)"
              />
              <div
                class="text-(fg-subtle)"
                v-html="sanitize(_truncate(programItem?.[props.section.lang.value]?.description, { length: useBuilderStore().agendaMaxDescriptionLength }))"
              />
              <div
                v-if="programItem.coverImage"
                class="flex"
                :class="[
                  props.section.styles.textAlignment.value,
                ]"
              >
                <div
                  class="overflow-hidden mt-2"
                  :class="[
                    props.section.styles.imageSize.value,
                    props.section.styles.imageCrop.value,
                    props.section.styles.cornerRadius.value,
                    {
                      'bg-(fg-muted)': !programItem.coverImage,
                    },
                  ]"
                >
                  <img
                    :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-1140,c-at_max/' : ''}${programItem.coverImage}`"
                    class="h-full object-cover"
                    :alt="sanitize(programItem?.[props.section.lang.value]?.title)"
                  >
                </div>
              </div>
            </div>
            <PageAgendaBasicItemDialog
              v-if="isDialogOpen[createDialogKey({ dayIndex, stageIndex, programIndex })]"
              :program-item="programItem"
              :lang="props.section.lang.value"
              :event="props.event"
              @close="isDialogOpen[createDialogKey({ dayIndex, stageIndex, programIndex })] = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  days: {
    type: Array,
    default: () => ([]),
  },

  section: {
    type: Object,
    default: () => ({}),
  },

  event: {
    type: Object,
    default: () => ({}),
  },
});

// Refs
const isDialogOpen = ref([]);

// Methods
const createDialogKey = ({ dayIndex, stageIndex, programIndex }) => {
  return `${dayIndex}${stageIndex}${programIndex}`;
};
</script>
