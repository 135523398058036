<template>
  <Dialog
    v-model="isOpen"
    size="small-hug"
    @close="emit('close')"
  >
    <div class="relative">
      <button
        class="transition text-gray-500 hover:text-gray-800 absolute top-0 right-0"
        aria-label="Close"
        @click="emit('close')"
      >
        <Icon
          name="x-close"
          class="w-5 h-5"
        />
      </button>
      <EventTimezoneDiffBadge
        :date="props.event.general?.startDate"
        :timezone="props.event.general?.timezone"
        :lang="props.lang?.replace('_', '')"
        class="mb-2 md:pt-2 pr-6"
      />
      <div class="text-gray-500">
        <template v-if="props.programItem.startTime !== props.programItem.endTime">
          {{ props.programItem.startTime }} - {{ props.programItem.endTime }}
        </template>
        <template v-else>
          {{ props.programItem.startTime }}
        </template>
      </div>
      <h3
        class="mt-1 font-bold text-2xl"
        v-html="sanitize(props.programItem?.[props.lang]?.title)"
      />
      <div
        class="text-(fg-subtle) mt-2"
        v-html="sanitize(props.programItem?.[props.lang]?.description)"
      />
      <div
        v-if="props.programItem.coverImage"
        class="overflow-hidden mt-6 w-full rounded"
      >
        <img
          :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-1140,c-at_max/' : ''}${programItem.coverImage}`"
          class="h-full w-full object-cover"
          :alt="sanitize(props.programItem?.[props.lang]?.title)"
        >
      </div>
    </div>
  </Dialog>
</template>

<script setup>
const props = defineProps({
  programItem: {
    type: Object,
    required: true,
  },

  lang: {
    type: String,
    required: true,
  },

  event: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close']);

// Refs
const isOpen = ref(true);
</script>
